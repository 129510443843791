import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "Fira Sans Extra Condensed",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 800,
      fontSize: 20
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Login title="ClickFunnel - Confirm it's you" />} />
          <Route exact path='/users/verify-action' element={<Login title="ClickFunnel - Confirm it's you" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
